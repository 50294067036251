<template>
  <div class="container-flex">
    <div class="left-tab-flex">
      <div class="new-info-button-div-flex">
        <el-button class="new-info-button-flex" @click="newChat">新建对话</el-button>
      </div>
      <div class="info-list-flex">
        <div v-for="info in chat_info_list" :key="info.chat_id" class="info">
          <el-button plain class="info_button" :id="info.chat_id" @click="toChat(info.chat_id)" >
            {{ info.description}}
          </el-button>

        </div>
      </div>


      <div class="new-info-button-div-flex2">
        <el-dropdown @command="handleUserManu">
          <el-button class="new-info-button-flex2">
            用户中心
          </el-button>
          <template v-slot:dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="manage">会员管理</el-dropdown-item>
              <el-dropdown-item command="plus bought">附加包购买</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>


        <el-dialog
            v-model="manage_dialog_visible"
            width="600px"
            title="购买套餐"
            class="upgrade-dialog"
        >
          <!-- 当前套餐信息 -->
          <div class="current-plan">
            <p>你当前的套餐：
              <strong v-if="!vip_info.is_vip"> 免费试用 </strong>
              <strong v-else> 会员用户 有效期至{{vip_info.expire_time}} </strong>

            </p>
          </div>

          <!-- 套餐选项 -->
          <div class="plans-container" v-if="!vip_info.is_vip">
            <div class="plan-box free-plan">
              <h3>免费试用</h3>
              <p>每月 0 元</p>
              <p>当前套餐</p>
              <p>每天登录可获得 {{try_count}} 次 ChatGPT 4o 对话服务</p>
            </div>

            <div class="plan-box member-plan">
              <h3>会员</h3>
              <p>每月 6 元</p>
              <p>ChatGPT 4o：每月 1000 次对话</p>
              <p>解锁图片分析功能</p>
            </div>
          </div>

          <div class="plans-container" v-if="vip_info.is_vip">
            <div class="plan-box member-plan">
              <h3>会员</h3>
              <p></p>
              <p>ChatGPT 4o包月: {{vip_info.txt_count_remain}}/{{vip_info.txt_count_total}} 次对话</p>
              <p>ChatGPT 4o附加次数: {{vip_info.txt_count_extra}} 次对话</p>
            </div>
          </div>

          <!-- 会员时长选项 -->
          <div class="membership-options">
            <button class="option-button" :class="{'option-button-clicked': dl_select[0] }" @click="choose_gift(1000, 0)">一个月会员<br />6元</button>
            <button class="option-button" :class="{'option-button-clicked': dl_select[1] }" @click="choose_gift(1001, 1)">三个月会员<br />18元</button>
            <button class="option-button" :class="{'option-button-clicked': dl_select[2] }" @click="choose_gift(1002, 2)">
              半年会员<br />32元 <span class="discount">88折</span>
            </button>
          </div>

          <!-- 底部按钮 -->
          <div class="dialog-footer">
<!--            <el-button @click="visible = false">关闭</el-button>-->
            <el-button type="primary" @click="confirmOrder('manage')">购买</el-button>
          </div>
        </el-dialog>


      <el-dialog
          v-model="qr_code.qrcode_dialog_visible"
          width="600px"
          title="支付"
          class="upgrade-dialog"
      >
        <div class="qr-code-container">
          <p>请使用 <strong>微信</strong> 扫描下方二维码完成支付</p>
          <img
              :src="qr_code.current_qrcode"
              alt="支付二维码"
              class="qr-code"
          />
        </div>


        <!-- 底部按钮 -->
        <div class="dialog-footer">
          <el-button @click="qrCodeClose()">关闭订单</el-button>
          <el-button type="primary" @click="qrCodeConfirm()">订单已支付</el-button>
        </div>
      </el-dialog>



      <el-dialog
          v-model="plus.dialog_visible"
          width="600px"
          title="附加包购买"
          class="upgrade-dialog"
      >
        <!-- 当前套餐信息 -->
        <div class="current-plan">
          <p>附加包为chatgpt重度用户设计, 因此对比月会员的单次对话成本更低</p>
          <p>但请注意！附加包购买的附加次数只有在会员的期间使用，免费用户购买无法使用!</p>

        </div>

        <div class="amount-options">
          <el-radio-group v-model="plus.selected_gift_id">
            <el-radio-button v-for="(gift, index) in plus.gift_info" :key="index" :label="gift.gift_id">
              {{ gift.amount }}元
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="result">
          本次购买获得：<strong>{{ plusGetGiftTextCount(plus.selected_gift_id) }}</strong> 附加对话次数
        </div>
        <!-- 底部按钮 -->
        <div class="dialog-footer">
          <!--            <el-button @click="visible = false">关闭</el-button>-->
          <el-button type="primary" @click="confirmOrder('plus bought')">购买</el-button>
        </div>
      </el-dialog>


    </div>


    <div class="right-tab-flex">
      <div class="messages-flex">
        <div v-for="message in talkData" :key="message.id" class="message" ref="message-container">
          <div class="content" :class="{ 'received': (message.role !== 'assistant' && message.role !== 'error') }">
            <div v-if="(message.msg_type === 'wait')" >
              <el-image class="wait-image" :src="waiting_image"></el-image>
            </div>
            <div v-else-if="(message.msg_type === 'text')">
              <div v-if="message.content_view !== ''">
                <v-md-preview :text="message.content_view"></v-md-preview>
              </div>
              <div v-else>
                {{message.content}}
              </div>
            </div>
            <div v-else-if="(message.msg_type === 'image_url')">
              <span>{{message.image_data.description}}</span>
              <img :id="message.image_data.url" class="message-image" v-if="message.image_data.local_url === ''" :src="message.image_data.show_url" @click="onLoadImage">
              <img class="message-image" v-else :src="message.image_data.local_url">
            </div>
            <div v-else-if="(message.msg_type === 'error')" >
              <div>{{message.content}}</div>
            </div>

          </div>
        </div>
      </div>
    <div v-if="showUpload" class="tools-flex">
        <el-upload
            action="#"
            ref="upload"
            list-type="picture-card"
            :auto-upload="false"
            :limit="limitCount"
            :onChange="handleImageChange"
            :on-remove="handleRemove"
            :disabled="!allowUpload"
            :class="{disable:!allowUpload}">
          <el-icon><Plus/></el-icon>
          <template #file="{ file }">
            <div>
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
              <span class="el-upload-list__item-actions">
              <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
              >
                <el-icon><Delete /></el-icon>
              </span>
            </span>
          </div>
          </template>

        </el-upload>
      </div>
      <div class="input-form-flex">
        <div class="input-tools">
          <el-button icon="el-icon-picture" @click="showUploadElement"> </el-button>
        </div>
        <form @submit.prevent="sendMessage">
<!--          <textarea  v-model="newMessage" placeholder="我是galigulu.com，请提问" />-->
          <el-input
              class="input-area"
              v-model="newMessage"
              type="textarea"
              placeholder="如果要联网搜索，则在问题中加入'联网搜索'字样"
              @keyup.enter.shift="inputNextLine"
              @keyup.enter.exact.prevent
              @keyup.enter.exact="sendMessage"
              autosize
          ></el-input>


          <button class="input-button" type="submit" :disabled="submitDisabled">发送</button>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
import {
  defaultOnError,
  exchangeCdkey,
  generateChat,
  getChatList,
  getLoginToken,
  getMessage,
  getVipInfo,
  sendMessages,
  uploadImage,
  wechatpayCreateOrder
} from "@/utils/HeadTool";
import {getErrorMessage} from "@/utils/BoloError";
import ImageLoading from "@/assets/loading.gif";
import ImageTouch from "@/assets/touch.png";
//import {boloApis} from "@/utils/Const";
import {Delete, Plus} from '@element-plus/icons';
import {nextTick} from 'vue'

let ROLE_USER = "user";
let ROLE_ASSISTANT = "assistant";
let ROLE_ERROR = 'error';


export default {
  name: "Mchat.vue",
  inject: ['app'],
  components: {
    Plus,
    Delete,
  },

  mounted: function () {
    //console.log("moutne")
    let router = this.$router

    let login_token_json = getLoginToken();
    if (login_token_json === null) {
      //console.log("token is null");
      this.tryLogin(router)
    } else {
      let login_token = JSON.parse(login_token_json);

      this.token = login_token['token'];
      this.code = login_token['code'];
      this.chat_info.uid = login_token['uid'];
      //console.log(this.token);

      this.tryGetChatList();
      this.tryGetVipInfo();
    }

  },




  data() {
    return {
      choosedImagePath: "",
      choosedImage:undefined,
      showUpload: false,
      allowUpload: true,
      limitCount:1,
      that: this,
      token: '',
      scene: '',
      code: '',
      talkData: [
        {
          role: 'assistant', // 发送人id
          msg_type: 'text', // 消息类型
          content: '我是galigulu.com，请提问',
          content_view: '',
          image_data:{},
          id: 'page1'
        }
      ],
      chat_info_list:[
        {
          chat_id: 0,
          creater: 0,
          description: "新对话",
          create_time: ""
        }
      ],
      newMessage: '',
      app_id: "wx1ba92280797295d6",
      ////// vefify field //

      ///// chat info ////
      chat_info : {
        uid: 0,
        r_uid: 10086,
        chat_id: 0
      },
      //// chat info ////

      /// model type ///
      ///model: 'gpt-3.5-turbo',
      /// model type ///

      /// history ///
      history_flag: true,
      /// history ///

      submitDisabled:false,
      waiting_image: ImageLoading,
      manage_dialog_visible: false,
      vip_info: {
        is_vip: 0,
        expire_time: '',
        txt_count_remain: 0,
        txt_count_total: 0,
        txt_count_extra: 0,
      },
      try_count: 3,
      current_gift_id: 0,
      dl_select:[0,0,0],

      qr_code: {
        qrcode_dialog_visible: false,
        current_qrcode: ''
      },

      plus: {
        dialog_visible: 0,
        selected_gift_id: 2000,
        gift_info : [
          {amount: 10, txt_count: 1800, gift_id: 2000},
          {amount: 50, txt_count: 9000, gift_id: 2001},
          {amount: 100, txt_count: 20000, gift_id: 2002},
          {amount: 200, txt_count: 40000, gift_id: 2003}
        ],

      }

    }
  },

  methods: {

    test() {
      //console.log("test");
    },

    plusGetGiftTextCount(gift_id) {
      let gifts = this.plus.gift_info
      for (let i = 0; i < gifts.length; i++) {
        if (gifts[i].gift_id === gift_id) {
          return gifts[i].txt_count
        }
      }
      return 0
    },

    qrCodeClose() {
      //console.log("qrcode close")
      this.qr_code.current_qrcode = ''
      this.closeDialog("qrcode")
    },

    qrCodeConfirm() {
      //todo: query the status
      this.qr_code.current_qrcode = ''
      this.closeDialog("qrcode")
    },

    tryGetVipInfo(callback) {
      let that = this;
      let req_data = {
        uuid: that.code,
        token: that.token,
        app_id: that.app_id
      };
      getVipInfo(that, req_data, function(context, info) {
        context.vip_info.expire_time = info.expire_time.substring(0, 10)
        context.vip_info.is_vip = 1
        context.vip_info.txt_count_extra = info.txt_count_extra
        context.vip_info.txt_count_remain = info.txt_count_remain
        context.vip_info.txt_count_total = info.txt_count_total
        if (callback !== undefined) {
          callback()
        }
      }, function(context, code) {
        if (code === 5019 || code === 5004) {
          if (callback !== undefined) {
            that.manage_dialog_visible = true
          }
          return
        }
        that.defaultOnError(context, code);
      })
    },

    handleUserManu(command) {
      let that = this
      //console.log("1")
      if (this.token === '') {
        return
      }
      if (command === "manage") {
        this.current_gift_id = 0
        this.tryGetVipInfo(function(){that.manage_dialog_visible = true})
      } else if (command === "plus bought") {
        if (this.vip_info.is_vip) {
          this.current_gift_id = 0
          this.plus.dialog_visible = true
        } else {
          this.$alert("免费用户无法购买加购包，请升级会员后再操作")
        }
      }
    },

    choose_gift(gift_id, index) {
      this.current_gift_id = gift_id
      this.dl_select[0] = 0;
      this.dl_select[1] = 0;
      this.dl_select[2] = 0;
      this.dl_select[index] = 1;
    },

    closeDialog(theDialog) {
      if (theDialog === "manage") {
        this.manage_dialog_visible = false
      } else if (theDialog === "qrcode") {
        this.qr_code.qrcode_dialog_visible = false
        this.tryGetVipInfo()
      } else if (theDialog === "plus bought") {
        this.plus.dialog_visible = false
      }
    },

    openDialog(theDialog) {
      if (theDialog === "manage") {
        this.manage_dialog_visible = true
      } else if (theDialog === "qrcode") {
        this.qr_code.qrcode_dialog_visible = true
      } else if (theDialog === "plus bought") {
        this.plus.dialog_visible = true

      }
    },

    confirmOrder(theDialog) {
      let that = this

      if (theDialog === "plus bought") {
        that.current_gift_id = that.plus.selected_gift_id
      }

      if (that.current_gift_id === 0) {
        this.$alert("请先选择套餐再购买")
        return
      }

      let data = {
        'we_verify': {
          'token': that.token,
          'uuid': that.code,
          'app_id': that.app_id,
        },
        gift_id: that.current_gift_id,
        gift_count: 1
      };

      wechatpayCreateOrder(that, data, function(context, qrcode_rc) {
        //console.log(qrcode_rc)
        that.closeDialog(theDialog)
        that.qr_code.current_qrcode = ''
        that.showPayQrcode(qrcode_rc)

      }, defaultOnError);

    },

    showPayQrcode(qrcode_rc) {
      this.qr_code.current_qrcode = qrcode_rc
      this.qr_code.qrcode_dialog_visible = true

    },

    scrollToBottom() {
      nextTick(() => {
        let container = document.getElementsByClassName('messages-flex')[0];
        container.scrollTop = 1000000;
      });
    },

    showImageUploading() {
      let obj = {id: "", role: 'user', msg_type: 'image_url', content:'', content_view:'', image_data: {url: "", description: '图片上传中', local_url: ImageLoading}}

      const talkData = this.talkData
      talkData.push(obj)
    },

    onLoadImage(res) {
      //console.log(res)
      let url = res.target.id;
      let data = this.talkData;
      data.forEach(function (item) {
        //console.log(item)
        if (item.image_data !== undefined) {
          if (item.image_data.show_url !== undefined && item.image_data.show_url === ImageTouch && item.image_data.url !== undefined && item.image_data.url === url) {
            item.image_data.show_url = url
          }
        }
      })
    },

    handleRemove(file) {
      console.log(file)
      //this.$refs.upload.handleRemove(file);
      this.$refs.upload.clearFiles();
      this.allowUpload = true;
      this.showUpload = true;
      this.choosedImagePath = '';
      this.choosedImage = ''
    },

    showUploadElement() {
      if (this.showUpload) {
        this.allowUpload = true;
        this.showUpload = false;
        this.choosedImagePath = '';
        this.choosedImage = ''
      } else {
        this.showUpload = true;
        this.choosedImagePath = '';
        this.choosedImage = ''
      }
    },

    handleImageChange(file, fileList) {
      if (fileList.length >= 1 ) {
        this.allowUpload = false;
        this.choosedImagePath = file.url
        this.choosedImage = file
      }
    },

    clearImageData() {
      this.$refs.upload.clearFiles();
      this.allowUpload = true;
      this.choosedImagePath = ''
      this.choosedImage = undefined
      this.showUpload = false
    },

    talkDataToHistory() {
      let data = this.talkData
      let that = this
      let history = []
      data.forEach(function (item) {
        let history_item = {}

        if (item['role'] === ROLE_USER) {
          history_item['sender'] = that.chat_info.uid
          history_item['receiver'] = that.chat_info.r_uid
        } else if (item['role'] === ROLE_ASSISTANT) {
          history_item['sender'] = that.chat_info.r_uid
          history_item['receiver'] = that.chat_info.uid
        } else if (item['role'] === ROLE_ERROR) {
          // if error , we shift the one cause error
          // todo: 调整Ui
          history.shift()
          return;
        }
        history_item['chat_id'] = that.chat_info.chat_id
        history_item['content'] = {}

        if (item['msg_type'] === 'text') {
          history_item['content']['content']= item['content']
          history_item['content']['msg_type']= item['msg_type']

        } else if (item['msg_type'] === 'image_url') {
          history_item['content']['url'] = item['image_data']['url']
          history_item['content']['description'] = item['image_data']['description']
        }
        history.unshift(history_item)
      })

      return history
    },

    clearTalkData() {
      let talkData = this.talkData;
      while(talkData.length > 1) {
        talkData.pop();
      }
    },

    // messagesToTalkData(messages) {
    //   let talkData = this.talkData
    //   let that = this;
    //   this.clearTalkData();
    //   messages.forEach(function (msg) {
    //     let item = {}
    //     item['msg_type'] = msg.content.msg_type;
    //     item['content'] = msg.content.content;
    //     if (msg.sender === 10086) {
    //       item['role'] = ROLE_ASSISTANT;
    //       item['content_view'] = that.markdownToData(item['content']);
    //     } else {
    //       item['role'] = ROLE_USER;
    //       item['content_view'] = '';
    //     }
    //     item['id'] = 'page1';
    //     talkData.push(item)
    //   })
    // },

    messagesToTalkData(messages) {
      let talkData = this.talkData
      let that = this;
      this.clearTalkData();

      messages.forEach(function (msg) {
        let item = {}
        item['msg_type'] = msg.content.msg_type;
        if (item['msg_type'] === 'text') {
          item['content'] = msg.content.content;
          if (msg.sender === 10086) {
            item['role'] = ROLE_ASSISTANT
            item['content_view'] = that.markdownToData(item['content'])
          } else {
            item['role'] = ROLE_USER
            item['content_view'] = ''
          }
          item['id'] = 'page1'
        } else if (msg.content.url !== undefined) {
          item['content'] = ''
          item['content_view'] = ''
          if (msg.sender === 10086) {
            item['role'] = ROLE_ASSISTANT
          } else {
            item['role'] = ROLE_USER
          }
          item['image_data'] = {}
          item['image_data']['url'] = msg.content.url
          item['image_data']['show_url'] = ImageTouch
          item['image_data']['description'] = msg.content.description
          item['image_data']['local_url'] = ""
          item['msg_type'] = 'image_url'
        }
        talkData.push(item)
      })
    },

    // message 转换
    // txt: 文字
    // file: 用户上传的file返回的唯一id
    // image: 用户上传的image返回的唯一id
    // voice: 用户上传的voice返回的唯一id
    talkDataToMessage(obj) {
      let message = []
      let data = {}
      let txt = ''
      let image = ''
      let file = ''
      let voice = ''
      if (obj.msg_type === 'text') {
        txt = obj.content
      } else if (obj.msg_type === 'image_url') {
        txt = obj.image_data.description
        image = obj.image_data.url
      }
      if (txt !== '') {
        data['sender'] = this.chat_info.uid;
        data['receiver'] = this.chat_info.r_uid;
        data['chat_id'] = this.chat_info.chat_id;
        data['content'] = {};

        data['content']['msg_type'] = 'text';
        data['content']['content'] = txt;
      } else {
        return message
      }

      if (file !== '') {
        //console.log(file)
      } else if (image !== '') {
        delete data['content'].content
        delete data['content'].msg_type
        data['content']['url'] = image
        data['content']['description'] = txt
      } else if (voice !== '') {
        //console.log(voice)
      }
      message.push(data)
      return message
    },

    onGenerateSuccess(context, chat_id) {
      context.chat_info.chat_id = chat_id;
      // add a new chat in list
      let new_info =
        {
          chat_id: chat_id,
          creater: 0,
          description: "新对话",
          create_time: ""
        };
      context.chat_info_list.push(new_info);

      // transe chat
      context.toChat(chat_id);
    },

    onGenerateFailed(context, error_code) {
      this.$alert(getErrorMessage(error_code));
    },

    generateNewChat(onSuccess) {
      let that = this;
      let data = {
        uuid: that.code,
        token: that.token,
        app_id: that.app_id
      };
      if (onSuccess === null) {
        onSuccess = that.onGenerateSuccess
      }
      generateChat(that, data, onSuccess, that.onGenerateFailed);
    },

    withdrawImageUploading() {
      const talkData = this.talkData
      talkData.pop()
    },

    onSend(obj) {
      let that = this
      let message = that.talkDataToMessage(obj);
      let history = that.talkDataToHistory();


      that.talkData.push(obj);
      that.addWaitMsg();


      that.newMessage = '';
      let data = {
        'we_verify': {
          'token': that.token,
          'uuid': that.code,
          'app_id': that.app_id,
        },
        'chat_id': that.chat_info.chat_id,
        'model': that.app.model,
        'content': message,
        'history': history
      };

      //console.log(data);

      sendMessages(that, data, that.onSendMessageSuccess, that.onWaitFailed);
      that.scrollToBottom();
    },

    requestSendMessage(that, chat_id) {
      if (!this.newMessage.trim()) {
        this.$alert("请输入问题再发送")
        return
      }
      that.chat_info.chat_id = chat_id;

      let obj = {
        content: this.newMessage.trim(),
        role: "user",
        msg_type: 'text',
        content_view: '',
        id: ""
      };

      if (this.choosedImagePath !== '') {
        this.showImageUploading();
        // first upload
        let verify =   {
          'token': that.token,
          'uuid': that.code,
          'app_id': that.app_id,
        };

        uploadImage(that, that.choosedImage, "upload.png", verify,
            function(url) {
                that.withdrawImageUploading()
                let obj = {
                  role: 'user',
                  msg_type: 'image_url',
                  image_data: {
                    url: url,
                    description: that.newMessage.trim(),
                    local_url: that.choosedImagePath,
                    show_url: url
                  }
                }
                that.onSend(obj)
                that.clearImageData()

            }, function(error) {
                console.log(error)
            });
        return;
      }

      this.onSend(obj);

    },

    sendMessage() {
      let that = this;
      //console.log(that.app.model);
      this.disableInput();
      if (this.token === '') {
        this.tryLogin(this.$router)
        return;
      }
      if (this.chat_info.chat_id === 0) {
        this.generateNewChat(that.requestSendMessage);
      } else {
        that.requestSendMessage(this, this.chat_info.chat_id);
      }
    },

    onSendMessageSuccess(that, content) {
      that.enableInput();
      //console.log(content);
      let response_data = content;
      //console.log(response_data);
      // 更新UI
      that.onWaitSuccess(response_data['role'], response_data['msg_type'], response_data['content']);
    },

    onWaitSuccess(role, msg_type, content) {

      let target_obj = {};
      target_obj['role'] = role;
      target_obj['msg_type'] = msg_type;
      target_obj['content'] = content;
      target_obj['content_view'] = this.markdownToData(content);
      target_obj['id'] = '1';

      this.removeWaitMsg();
      this.talkData.push(target_obj);
    },


    onWaitFailed(context, reason) {
      //console.log("onWaitFailed");
      this.removeWaitMsg();
      let target_obj = {};
      target_obj['role'] = ROLE_ERROR;
      target_obj['msg_type'] = "error";
      target_obj['content'] = getErrorMessage(reason);
      target_obj['content_view'] = getErrorMessage(reason);
      context.talkData.push(target_obj);
      if (reason === 5005 || reason === 5015) {
        this.$alert("oops ! 今日试用次数已达上限，请在用户中心购买会员或加qq群 817647474  解锁继续使用");
      }
      if (reason === 5012) {
        this.$alert("id "+ context.chat_info.uid +"gpt4试用次数已达上限，请使用gpt3.5-turbo，或加qq群 817647474 解锁继续使用");
      }
      this.enableInput();
    },

    addWaitMsg() {
      let wait_obj = {role: ROLE_ASSISTANT, msg_type: 'wait', content: '', content_view: '', id: '转圈'}
      this.talkData.push(wait_obj);
    },

    removeWaitMsg() {
      this.talkData.pop();
    },

    enableInput() {
      this.submitDisabled = false;
    },

    disableInput() {
      this.submitDisabled = true;
    },

    markdownToData(data) {
      //todo: complete this
      return data;
    },

    toChat(chat_id) {
      if (this.submitDisabled) {
        return;
      }
      //console.log(chat_id);
      let chat_id_int = 0;
      if ((typeof chat_id) === 'number') {
        chat_id_int = chat_id;
      } else {
        chat_id_int = parseInt(chat_id);
      }
      this.doChatTranse(chat_id_int);
    },

    doChatTranse(chat_id) {
      // 获取聊天内容
      let that = this;
      let req_data = {
        we_verify: {
          uuid: that.code,
          token: that.token,
          app_id: that.app_id
        },
        chat_id: chat_id
      };

      getMessage(that,
          req_data,
          this.loadMessages,
          defaultOnError
      );


    },



    loadMessages(context, messages) {
      let that = context;

      that.messagesToTalkData(messages);
      that.scrollToBottom();
    },


    tryGetChatList() {
      let that = this;
      let req_data = {
        uuid: that.code,
        token: that.token,
        app_id: that.app_id
      };
      getChatList(that, req_data, that.onGetChatList, defaultOnError)


    },

    onGetChatList(context, info) {
      let that = context;
      //console.log(info);
      that.chat_info_list = [];
      info.forEach(element => {
        element.create_time = element.create_time.substring(0, 10);
        that.chat_info_list.unshift(element);
      });
    },

    newChat() {
      if (this.submitDisabled) {
        return;
      }
      if (this.token === '') {
        this.tryLogin(this.$router);
        return;
      }
      this.generateNewChat(null)
    },

    tryLogin(router) {
      this.$confirm("当前未登录，是否登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
        closeOnClickModal: false
      }).then(function () {
        router.push({path: "/pages/scanlogin"});
      })
    },

    inputNextLine() {
    },

    doExchangeCdkey() {
      let that = this;
      let data = {
        'we_verify': {
          'token': that.token,
          'uuid': that.code,
          'app_id': that.app_id,
        },
        'cdk': '100322266M'
      };
      exchangeCdkey(that, data, function (context, res) {
            console.log(res)
          },
          function(context, code) {
            console.log(code)
          }
      )
    }

  },



}


</script>

<style scoped>


.container-flex {
  display: flex;
  height: 90vh;
}


.left-tab-flex {
  flex: 0 0 15%;
  display: flex;
  flex-direction: column;
  /*border-right: 1px solid #ddd;*/
}


.right-tab-flex {
  flex: 1;
  display: flex;
  flex-direction: column;
}


.info-list-flex {
  overflow-y: scroll;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
}

.new-info-button-div-flex {
  display: flex;
  flex: 0 0 60px;
  border-right: 1px solid #ddd;
}

.new-info-button-div-flex2 {
  display: flex;
  flex: 0 0 60px;
}

.new-info-button-flex {
  background-color: #42b983;
  color: white;
  cursor: pointer;
  height: 34px;
  flex: 1;
  align-self: center;
  transition: background-color 0.3s;
}

.new-info-button-flex2 {
  background-color: #42b983;
  color: white;
  cursor: pointer;
  height: 34px;
  flex: 1;
  align-self: center;
  transition: background-color 0.3s;
}

.new-info-button-flex:hover {
  background-color: #36a773;
}

.new-info-button-flex2:hover {
  background-color: #36a773;
}

.info {
  /*float: left;*/
  /*background-color: #f5f5f5;*/
  /*border: 1px solid #ddd;*/
  /*border-radius: 2px;*/
  padding: 5px;
  word-wrap: break-word;
  border-right: 1px;
}

.info_button {
  /*float: left;*/
  background-color: #f5f5f5;
  width: 100%;
  text-align: left;
  padding: 10px;
  /*margin-bottom: 5px;*/
  margin-top: 10px;
}

.info_button:hover {
  background-color: #cfd8dc;
  border: #42b983;

}

.messages-flex {
  flex: 1;
  overflow-y: scroll;
  border-bottom: 1px solid #ddd;
  /*border-left: 1px solid #ddd;;*/
  padding: 20px;
  border-radius: 4px;
}

.message {
  clear: both;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  position: relative;
}

.message .content {
  float: left;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  max-width: 500px;
  word-wrap: break-word;
}

.message .content.received {
  float: right;
  background-color: #42b983;
  color: #fff;
  padding: 5px 10px;
}


.input-form-flex {
  flex: 0 0 60px;
  display: flex;
}

.wait-image {
  height: 50px;
  width: 50px;
}

.input-tools {
  flex: 0 0 5%;
  align-self: center;
}

form {
  display: flex;
  flex: 0 0 95%;
  height: 100%;
}

.message-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}


.input-area {
  flex: 0 0 90%;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  align-self: center;
  margin-right: 10px;
}

.input-area el-input__inner {
  height: 36px; /* 设置自适应高度 */
  line-height: 1.5; /* 行高与字体大小的比例 */
  padding: 3px 5px; /* 内边距 */
  box-sizing: border-box; /* 盒模型 */
}

.input-button {
  flex: 1;
  align-self: center;
  height: 36px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

form button {
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex: 0 0 10%;
}

.disable ::v-deep .el-upload--picture-card {
  display: none;
}


::v-deep .el-dropdown {
  display: flex;
  flex: 1;
}

::v-deep .vuepress-markdown-body{
  background-color: #f5f5f5;
}

.upgrade-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.current-plan {
  margin-bottom: 20px;
  font-size: 16px;
}

/* 套餐选项容器 */
.plans-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin: 20px 0;
}

.plan-box {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #f5f5f5;
  text-align: left;
}

.member-plan {
  background-color: #e0f7fa;
}

/* 会员时长按钮选项 */
.membership-options {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin: 20px 0;
}

.option-button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #42b983;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option-button-clicked {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #f1b16b;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option-button:hover {
  background-color: #f1b16b;
}


.discount {
  font-size: 12px;
  color: #ff5722;
  margin-left: 5px;
}

/* 底部按钮 */
.dialog-footer {
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  margin-top: 10px;
}


.qr-code-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.qr-code {
  width: 200px;
  height: 200px;
  border: 2px solid #ddd;
  border-radius: 8px;
}


.amount-options {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 20px 0;
}

.result {
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
}
</style>
